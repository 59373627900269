import Icons from 'images/icons';

// タイヤ交換
export const SERVICE_CODE_TIRE = '001';
// 車検
export const SERVICE_CODE_SYAKEN = '002';
// オイル交換
export const SERVICE_CODE_OIL = '003';
// ドラレコ取付
export const SERVICE_CODE_DRIVE_RECORDER = '004';
// バッテリー交換
export const SERVICE_CODE_BATTERY = '005';
// ワイパー交換
export const SERVICE_CODE_WIPER = '006';
// 法定12ヶ月点検
export const SERVICE_CODE_STATUTORY = '007';
// 車査定
export const SERVICE_CODE_ASSESSMENT = '008';
// 一般作業
export const SERVICE_CODE_WORK = '009';
// 洗車
export const SERVICE_CODE_WASH = '010';
// リフレッシュ
export const SERVICE_CODE_REFRESH = '011';
// 整備・スポーツ
export const SERVICE_CODE_MAINTENANCE = '012';
// カーエレ
export const SERVICE_CODE_ELECTRONICS = '013';
// エアコンフィルター交換
export const SERVICE_CODE_AIR_FILTER = '014';
// 無料安全点検
export const SERVICE_CODE_FREE_INSPECTION = '015';
// カー用品買取査定
export const SERVICE_CODE_ACCESSORIES = '016';
// 板金
export const SHEET_METAL = '017';

// 一緒に別の作業を選択できるサービス
export const MULTI_SELECT_SERVICE = [
  SERVICE_CODE_WORK,
  SERVICE_CODE_REFRESH,
  SERVICE_CODE_MAINTENANCE,
];

// 小カテゴリを選択しないサービス
export const NO_SMALL_SELECT_SERVICE = [
  SERVICE_CODE_DRIVE_RECORDER,
  SERVICE_CODE_STATUTORY,
  SERVICE_CODE_ASSESSMENT,
  SERVICE_CODE_AIR_FILTER,
  SERVICE_CODE_FREE_INSPECTION,
  SHEET_METAL,
];

// タイヤ交換定義（アイコン表示が必要なもの）
export const TIRE_SWITCH_LABEL = '夏⇔冬 入替';
export const TIRE_SWITCH_CHUBUN_CD = '009';
export const TIRE_CATEGORY_CODE_WHEEL = '101';
export const TIRE_CATEGORY_CODE_WHEEL_LABEL = 'ホイール付き入替';
export const TIRE_CATEGORY_CODE_WHEEL_ICON = Icons.tire;
export const TIRE_CATEGORY_CODE_TIRE = '102';
export const TIRE_CATEGORY_CODE_TIRE_LABEL = 'タイヤのみ入替';
export const TIRE_CATEGORY_CODE_TIRE_ICON = Icons.tire02;

// 法廷12か月点検 (chubunCdが003と029の文言そろえる)
export const VEHICLE_INSPECTION_CODE = '003';
export const STATUTORY_INSPECTION_CODE = '103';

// 店舗・サービス選択画面の遷移パターン
export const SERVICE_SELECT_TRANSITION_TYPE = {
  /** ヘッダー「店舗から予約」押下で遷移 */
  HEADER: '1',
  /** 外部遷移パターンNo2(中分類コード指定) */
  BRAND_WORK: '2',
  /** 外部遷移パターンNo4(店舗指定) */
  SHOP_INFO: '3',
  /** 外部遷移パターンNo5(店舗、中分類指定) */
  SHOP_INFO_WORK: '4',
  /** 予約完了画面内の交換時期が迫っていますから遷移(店舗、中分類(小分類)指定) */
  COMPLETE: '5',
  /** 車のカルテ画面から遷移(店舗、中分類(小分類)指定) */
  KARTE: '6',
};

// 店舗・サービス選択画面の上部エリアタイトル
export const SERVICE_SELECT_MAIN_LABEL = {
  [SERVICE_SELECT_TRANSITION_TYPE.HEADER]: 'よくご利用いただく店舗',
  [SERVICE_SELECT_TRANSITION_TYPE.BRAND_WORK]: 'よくご利用いただく店舗',
  [SERVICE_SELECT_TRANSITION_TYPE.SHOP_INFO]: '',
  [SERVICE_SELECT_TRANSITION_TYPE.SHOP_INFO_WORK]: '',
  [SERVICE_SELECT_TRANSITION_TYPE.COMPLETE]: '予約した店舗',
  [SERVICE_SELECT_TRANSITION_TYPE.KARTE]: 'よくご利用いただく店舗',
};

// 日付選択画面の遷移パターン
export const DATE_SELECT_TRANSITION_TYPE = {
  /** 店舗選択画面から遷移 */
  SHOP_SELECT: '1',
  /** 店舗・サービス選択画面から遷移 */
  SERVICE_SELECT: '2',
  /** 予約確認画面から遷移 */
  MODIFY: '3',
  /** 店舗在庫引当入荷お知らせメールURLから遷移 */
  SHOP_STOCK_MORTGAGE: '4',
  /** 外部遷移パターン No6(店舗、中分類(小分類)指定) */
  SHOP_INFO_WORK: '5',
  /** 外部遷移パターン No6(sid指定) */
  SHOP_INFO_SID: '6',
};
